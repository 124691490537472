<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="row m-t-10">
      <div class="col-lg-10 col-md-12 mx-auto">
        <h2 class="font-30">HeaLoとは</h2>
        <p class="mt-4 font-16">
          HeaLoとは「Health Information Logging System(健康情報管理システム)」の略称です。<br/>
          新型コロナウイルスは世界のスポーツ界に未曽有の影響を与えており、テニス界でも多くの大会が中止を余儀なくされています。<br/>
          With コロナ・After コロナの時代において、本システムの活用を通じて、選手・運営スタッフ・審判をはじめとする大会・スポーツイベントに関わる全ての人にとって、安心・安全な環境の整備がなされ開催されることを願います。<br/>
        </p>
        <div class="col mt-5 d-flex">
          <div>
            <p class="font-13 font-weight-medium">運営元</p>
            <a href="https://www.jta-tennis.or.jp/" target="_blank"><img src="../assets/images/jta_logo_yoko_fullcolor.png"></a>
          </div>
          <div class="mx-5">
            <p class="font-13 font-weight-medium">開発元</p>
            <a href="https://dreamonline.co.jp/" target="_blank"><img class="my-3" src="../assets/images/dol.svg"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Credit"
}
</script>

<style lang="scss" scoped>
.credit-title {
  max-width: 720px;
  line-height: 30px;
}
</style>